<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2" v-if="!passwordChanged">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <price-x-logo/>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('title.password_reset') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('messages.enter_your_new_password') }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
          >
            <b-form-group
                :label="$t('label.new_password')"
                label-for="forgot-password-newpassword"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|confirmed:Confirmation"
              >
                <b-form-input
                    id="forgot-password-newpassword"
                    v-model="newPassword"
                    :state="errors.length > 0 ? false:null"
                    type="password"
                    name="forgot-password-newpassword"
                    placeholder="*********"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
                :label="$t('label.confirm_password')"
                label-for="forgot-password-passwordconfirmation"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Confirmation"
                  rules="required"
              >
                <b-form-input
                    id="forgot-password-passwordconfirmation"
                    v-model="passwordConfirmation"
                    type="password"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-passwordconfirmation"
                    placeholder="*********"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-overlay :show="isLoading" rounded spinner-small
                       spinner-variant="primary" class="d-inline-block">
              <b-button
                  variant="primary"
                  block
                  type="submit"
              >
                {{ $t('buttons.change_password') }}
              </b-button>
            </b-overlay>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth.login'}">
            <feather-icon icon="ChevronLeftIcon"/>
            {{ $t('buttons.back_to_login') }}
          </b-link>
        </b-card-text>

      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
    <div class="auth-inner py-2" v-if="passwordChanged">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <price-x-logo/>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('title.password_reset') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('messages.your_password_was_updated') }}
        </b-card-text>
        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth.login'}">
            <feather-icon icon="ChevronLeftIcon"/>
            {{ $t('buttons.back_to_login') }}
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import PriceXLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton,
  BOverlay
} from 'bootstrap-vue'
import {required, confirmed} from '@validations'

export default {
  components: {
    PriceXLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  metaInfo() {
    return {
      title: this.$t('page_titles.forgotten_password')
    }
  },
  data() {
    return {
      newPassword: null,
      passwordConfirmation: null,
      isLoading: false,
      passwordChanged: false,
      //validation
      required,
      confirmed
    }
  },
  name: "ResetPassword",
  methods: {
    validationForm() {
      this.isLoading = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$http.post('/user/reset-password', {
            password: this.newPassword,
            password_confirmation: this.passwordConfirmation,
            email: this.$route.query.email,
            token: this.$route.query.token
          }).then(() => {
            this.passwordChanged = true
          }).catch(error => {
            if (error.response.data.errors.email[0] === 'passwords.token') {
              this.$refs.simpleRules.setErrors({Password: 'Expired token'})
            }
          }).finally(() => {
            this.isLoading = false
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>